import { useEffect, useState } from "react";
import { Dialog, Box } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";

const RaceboardProject = ({ open, onClose, app }) => {
  const dispatch = useDispatch();
  const year = useSelector((state) => state.year.value);
  const raceType = useSelector((state) => state.raceType.value);

  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [row1L, setRow1L] = useState('');
  const [row1R, setRow1R] = useState('');
  const [row2L, setRow2L] = useState('');
  const [row2R, setRow2R] = useState('');
  const [row3L, setRow3L] = useState('');
  const [row3R, setRow3R] = useState('');
  const [img1, setImg1] = useState('');
  const [img2, setImg2] = useState('');

  useEffect(() => {
    
    let level = app.raceboard.layer;
    let lev = 'state';
    if (level === 'districts')
      lev = 'district';
    else if (level === 'municipals')
      lev = 'municipal' 
  
    let title = '';
    let subTitle = '';
    let row1L = '';
    let row1R = '';
    let row2L = '';
    let row2R = '';
    let row3L = '';
    let row3R = '';
    let img1 = '';
    let img2 = '';
    if (app.projectData[app.projectYearID] && app.projectData[app.projectYearID][lev]) {       
      const data = app.projectData[app.projectYearID][lev];
      for (let i = 0; i < data.length; i++) {
        if (data[i].length > 1) {
          if (level === 'states') {
            if (app.raceboard.stateId === parseInt(data[i][0])) {
              title = data[i][2] ? data[i][2].trim() : '';
              subTitle = data[i][3] ? data[i][3].trim() : '';
              row1L = data[i][4] ? data[i][4].trim() : '';
              row1R = data[i][5] ? data[i][5].trim() : '';
              row2L = data[i][6] ? data[i][6].trim() : '';
              row2R = data[i][7] ? data[i][7].trim() : '';
              row3L = data[i][8] ? data[i][8].trim() : '';
              row3R = data[i][9] ? data[i][9].trim() : '';
              img1 = data[i][10] ? data[i][10].trim() : '';
              img2 = data[i][11] ? data[i][11].trim() : '';
            }
          }
          else if (level === 'districts') {
            //to do...
          }
          else if (level === 'municipals') {
            if (app.raceboard.munId === data[i][0] + data[i][1]) {
              title = data[i][3] ? data[i][3].trim() : '';
              subTitle = data[i][4] ? data[i][4].trim() : '';
              row1L = data[i][5] ? data[i][5].trim() : '';
              row1R = data[i][6] ? data[i][6].trim() : '';
              row2L = data[i][7] ? data[i][7].trim() : '';
              row2R = data[i][8] ? data[i][8].trim() : '';
              row3L = data[i][9] ? data[i][9].trim() : '';
              row3R = data[i][10] ? data[i][10].trim() : '';
              img1 = data[i][11] ? data[i][11].trim() : '';
              img2 = data[i][12] ? data[i][12].trim() : '';
            }
          }
        }
      }
      
      setTitle(title);
      setSubTitle(subTitle);
      setRow1L(row1L);
      setRow1R(row1R);
      setRow2L(row2L);
      setRow2R(row2R);
      setRow3L(row3L);
      setRow3R(row3R);
      setImg1(img1);
      setImg2(img2);
    } 
  }, [year, raceType, dispatch, app.projectData, app.projectYearID, app.raceboard]);

  return (
    <Dialog
      id={"raceboardP"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={0}
      sx={{
        display: (!title && !subTitle && !row1L && !row1R) ? "none" : "flex",
        flexDirection: "row",
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: window.innerWidth > 3000 ? 220 : 89,
        width: "auto",
        background: "transparent"
      }}
    >
      <Box
        sx={{
          width: window.innerWidth > 3000 ? 860 : 480,
          marginRight: window.innerWidth > 3000 ? 0 : 0
        }}
      >
        <Box className={"raceboardNWomen"}>
          <Box sx={{height: "20px"}}></Box>
          <Box
            className={"raceboardPRTitle"}
            sx={{
              fontSize: "50px",
              lineHeight: "96px",
              height: "96px",
              width: "100%",
              fontWeight: "bold",
              margin: "0px 8%",
              display: title ? 'block' : 'none', 
            }}
          >
            {title}
          </Box>
          <Box
            className={"raceboardPRSubtitle"}
            sx={{
              fontSize: "40px",
              lineHeight: "45px",
              paddingBottom: "0px",
              paddingTop: "0px",
              fontWeight: 300,
              textAlign: "left",
              margin: "0px 8%",
              marginTop: title ? "-20px" : "20px",
              display: subTitle ? 'block' : 'none', 
            }}
          >
            {subTitle}
          </Box>
          <Box
            className={"raceboardPRTopSpace"} 
            sx={{
              width: "92%",
              flexDirection: "row",
              paddingBottom: "17px",
              marginLeft: "8%",
              paddingTop: "20px",
              display: (row1L || row1R) ? 'flex' : 'none', 
            }}
          >
            <Box
              className={"raceboardPRTextL"}
              sx={{
                width: row1R ? "50%" : "100%",
                fontSize: "38px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row1L.substring(0,4) === 'http' && <img src={row1L} alt="" class={'projectRowImg'} />}
              {row1L.substring(0,4) !== 'http' ? row1L : ''}
            </Box>
            <Box
              className={"raceboardPRTextR"}
              sx={{
                width: row1R ? "50%" : "0%",
                fontWeight: "300",
                fontSize: "82px",
                lineHeight: "72px",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row1R.substring(0,4) === 'http' && <img src={row1R} alt="" class={'projectRowImg'} />}
              {row1R.substring(0,4) !== 'http' ? row1R : ''}
            </Box>
          </Box>
          <Box
            sx={{
              width: "92%",
              flexDirection: "row",
              paddingBottom: "17px",
              borderTop: "solid 1px",
              marginLeft: "8%",
              paddingTop: "20px",
              display: (row2L || row2R) ? 'flex' : 'none', 
            }}
          >
            <Box
              className={"raceboardPRTextL"}
              sx={{
                width: row2R ? "50%" : "100%",
                fontSize: "38px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row2L.substring(0,4) === 'http' && <img src={row2L} alt="" class={'projectRowImg'} />}
              {row2L.substring(0,4) !== 'http' ? row2L : ''}
            </Box>
            <Box
              className={"raceboardPRTextR"}
              sx={{
                width: row2R ? "50%" : "0%",
                fontWeight: "300",
                fontSize: "82px",
                lineHeight: "72px",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row2R.substring(0,4) === 'http' && <img src={row2R} alt="" class={'projectRowImg'} />}
              {row2R.substring(0,4) !== 'http' ? row2R : ''}
            </Box>
          </Box>
          <Box
            sx={{
              width: "92%",
              flexDirection: "row",
              paddingBottom: "12px",
              borderTop: "solid 1px",
              marginLeft: "8%",
              paddingTop: "20px",
              display: (row3L || row3R) ? 'flex' : 'none', 
            }}
          >
            <Box
              className={"raceboardPRTextL"}
              sx={{
                width: row3R ? "50%" : "100%",
                fontSize: "38px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row3L.substring(0,4) === 'http' && <img src={row3L} alt="" class={'projectRowImg'} />}
              {row3L.substring(0,4) !== 'http' ? row3L : ''}
            </Box>
            <Box
              className={"raceboardPRTextR"}
              sx={{
                width: row3R ? "50%" : "0%",
                fontWeight: "300",
                fontSize: "82px",
                lineHeight: "72px",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row3R.substring(0,4) === 'http' && <img src={row3R} alt="" class={'projectRowImg'} />}
              {row3R.substring(0,4) !== 'http' ? row3R : ''}
            </Box>
          </Box>
          <Box className={"raceboardPRBottomSpace"} sx={{height: "44px"}}></Box>
        </Box>
        
        <Box 
          sx={{            
            marginTop: "4px",
            borderRadius: "5px",
            display: img1 ? 'flex' : 'none', 
          }}
        >         
          <Box
            sx={{
              width: img2 ? "50%" : "100%",
              display: "flex"
            }}
          >
            <img className={"raceboardNWomen"} src={img1} alt="" style={{ width: "100%", backgroundColor: "transparent" }} />
          </Box>
          <Box
            sx={{
              width: img2 ? "49%" : "0%",
              marginLeft: img2 ? "1%" : "0%",
              display: "flex"
            }}
          >
            <img className={"raceboardNWomen"} src={img2} alt="" style={{ width: "100%", backgroundColor: "transparent" }} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RaceboardProject;
